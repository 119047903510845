<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center mt-5 mb-5">
      <Preloader/>
    </div>

    <div v-else class="mt-5 p-2">
      <h4 class="text-center">Мои договора</h4>
      <div v-if="contracts.length > 0">
        <div class="my-3">
          <div class="row">
            <div v-for="(item, index) in contracts" :key="index" class="col-md-4 my-2">
              <Card style="width: 25em">
                <template #title>
                    <div class="col-md-">
                      Контракт № {{ item.contract_number }}
                    </div>
                </template>
                <template #subtitle>
                  Договор: <a :href="'https://back.uib.kz/documents_uploads/'+item.file_url" target="_blank"><i class="pi pi-file-pdf text-danger" style="font-size: 1rem"></i></a>
                </template>
                <template #footer>
                  <div>
                    <span class="bg-primary rounded-3 px-2 py-1 text-light me-2">{{ convertTimestampToDate(item.contract_start_date) }}</span>
                    <span class="bg-secondary rounded-3 px-2 py-1 text-light">{{ convertTimestampToDate(item.contract_end_date)  }}</span>
                  </div>
                </template>
              </Card>
            </div>
          </div>
          <!--      <label class="me-2">Опыт преподавания</label>-->
          <!--      <input type="checkbox">-->
        </div>

      </div>
      <div v-else>
        <p>Договоров нет</p>
      </div>


    </div>

  </div>
</template>


<script>
import userInfo from "../services/userInfo"
import httpClient from "@/services/http.service"
import {convertTimestampToDate} from "@/utils/helpers/convertTimestampToDate"
import {getCookie} from "@/utils/helpers/cookies.helpers";
export default {
  name: "MyContracts",

  data() {
    return {
      //user: null,
      contracts: []
    }
  },
  methods: {

    async getContracts(barcode) {
      try {
        const {status, data} = await httpClient.get(`labor_contract/teacher-labor-contract/get-pps-contracts?access_token=${getCookie('ACCESS_TOKEN')}`)
        if (status === 200 && data) {
          this.contracts = data
          return true
        }
      } catch (e) {
        this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
      }
      return false
    },

    // async setupData() {
    //   this.user = await userInfo.getUserInfo()
    // },
    convertTimestampToDate
  },
  async mounted() {
    await this.getContracts()
    this.loading = false
  }
}

</script>
<style scoped>

</style>

