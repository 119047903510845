import httpClient from "./http.service"
import router from "../router"

const userInfo = {
  userInfo: null,
  studentInfo: null,
  async getUserInfo() {
    try {
      const {status, data} = await httpClient.get('api/user/full-data')
      if (status === 200) {
        this.userInfo = data
      }
    } catch (e) {
      return null
    }
    return this.userInfo
  },
  async getStudentInfo() {
    try {
      const {status, data} = await httpClient.get('api/user/student-full-data')
      if (status === 200) {
        this.studentInfo = data
      }
    } catch (e) {
      return null
    }
    return this.studentInfo
  },
  changeEmail(formData) {
    return httpClient.post(`api/user/change-mail`, formData)
  },
  changeEmailAndPhone(formData) {
    return httpClient.post(`api/user/change-mail-and-phone`, formData)
  }
}
export default userInfo